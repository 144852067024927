import React , { useState } from 'react'
import './Contact.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";

export default function Contact() {

    // const alert = useAlert()
    const [ loading , setLoading ] = useState(false)

    const [ user , setUser ] = useState({
        name : '' ,
        email : "" ,
        mobile : '' ,
        message : ""
    })

    const [ error , setError ] = useState({
        name : '' ,
        email : "" ,
        mobile : '' ,
        message : ""
    })

    function validateEmail(mail) 
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email))
            return true 
        else 
            return false
    }

    let validateForm = () => {

        let isValid = true 
        let { name , email , mobile , message } = user

        if ( name.length <= 2 )
        {
            setError({ ...error , name : "Enter at least 3 characters"}) 
            isValid = false 
        }
        else if ( !validateEmail(email) )
        {
            setError({ ...error , email : "Invalid Email"}) 
            isValid = false 
        }
        else if ( mobile.length < 9 || mobile.length > 11 )
        {
            setError({ ...error , mobile : "Invalid mobile number" })
            isValid = false 
        }
        else if ( message.length < 10 || message.length > 300 )
        {
            setError({ ...error , message : "Enter 10 - 300 characters"}) 
            isValid = false 
        }

        return isValid
    }

    const notifySuccess = () => {
        toast.success('Form Submitted', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }

    const notifyError = () => {
        toast.error('An Error occured', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }

    let handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if( validateForm() )
        {
            await axios.post("https://getform.io/f/c87b32a4-e364-48fe-8016-6af42a190921", 
            {
                ...user
            }
            , 
            { headers: {'Accept': 'application/json'}})
            .then( response => {
                notifySuccess()
                setUser({
                    name : '' ,
                    email : "" ,
                    mobile : '' ,
                    message : ""
                })
            })
            .catch(error => {
                console.log(error)
                notifyError()
            })
        }
        setLoading(false)
    }

    let handleChange = ( field , value ) => {
        setUser({ ...user , [field] : value })
    }

  return (
    <div className="site-wrapper contact-page">
        <Helmet>
            <title>Contact Us || Transformatics</title>
            <meta name="description" content="Contact us for help" />
        </Helmet>
        <section className="contact-us-parent section-spacing">
            <div className="contact-us-inner container">
            <div className="section-heading text-center">
                <h6>NEED HELP?</h6>
                <h1>Get In Touch With us</h1>
            </div>
            <div className="contact-us-content mt-5">
                <div className="row">
                <div className="col-xl-6">
                    <div className="contact-us-highlights">
                    <div className="contact-us-highlight-item">
                        <div className="rounded-circle-50 mb-4">
                        <img src="./images/fb.png" width="25px" alt="" />
                        </div>
                        <h5 className="mb-3 text-purple fw-bold">Facebook Page</h5>
                        <a
                        className="text-gray"
                        href="https://www.facebook.com/transformatics.pk/"
                        >
                        facebook.com/transformatics.pk
                        </a>
                    </div>
                    <div className="contact-us-highlight-item">
                        <div className="rounded-circle-50 mb-4">
                        <img
                            src="./images/phone-white.png"
                            width="25px"
                            alt=""
                        />
                        </div>
                        <h5 className="mb-3 text-purple fw-bold">Call Us</h5>
                        <a className="text-gray" href="tel:+923099077000">
                        +92 309 9077000
                        </a>
                    </div>
                    <div className="contact-us-highlight-item">
                        <div className="rounded-circle-50 mb-4">
                        <img src="./images/email.png" width="25px" alt="" />
                        </div>
                        <h5 className="mb-3 text-purple fw-bold">Email</h5>
                        <a className="text-gray" href="mailto:Info@transformatics.pk">
                        Info@transformatics.pk
                        </a>
                    </div>
                    <div className="contact-us-highlight-item">
                        <div className="rounded-circle-50 mb-4">
                        <img
                            src="./images/location.png"
                            width="25px"
                            alt=""
                        />
                        </div>
                        <h5 className="mb-3 text-purple fw-bold">Our Location</h5>
                        <a
                        className="text-gray"
                        href="https://www.facebook.com/transformatics.pk/"
                        >
                        Islamabad , Pakistan
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="contact-us-form-parent">
                    <form
                        className="contact-us-form"
                        onSubmit={handleSubmit}
                        name="transformatics"
                        id="contactForm"
                        data-netlify="true"
                    >
                        {/* <input type="hidden" name="form-name" value="contact" /> */}
                        <input
                        value={user.name}
                        type="text"
                        className="w-100"
                        placeholder="Name*"
                        name="name"
                        id="name"
                        onChange={(e) => handleChange( "name" , e.currentTarget.value )}
                        />
                        {
                            error.name ? <div className="error">{error.name}</div> : <></>
                        }
                        <input
                        type="text"
                        value={user.email}
                        className="w-100"
                        placeholder="Email*"
                        name="email"
                        id="email"
                        onChange={(e) => handleChange( "email" , e.currentTarget.value )}
                        />
                        {
                            error.email ? <div className="error">{error.email}</div> : <></>
                        }
                        <input
                        type="text"
                        value={user.mobile}
                        className="w-100"
                        placeholder="Phone*"
                        name="mobile"
                        id="mobile"
                        onChange={(e) => handleChange( "mobile" , e.currentTarget.value )}
                        />
                        {
                            error.mobile ? <div className="error">{error.mobile}</div> : <></>
                        }
                        <textarea
                        name="message"
                        value={user.message}
                        id="message"
                        cols={30}
                        rows={7}
                        className="w-100"
                        placeholder="Your message"
                        onChange={(e) => handleChange( "message" , e.currentTarget.value )}
                        />
                        {
                            error.message ? <div className="error">{error.message}</div> : <></>
                        }
                        <button className="generic-btn-1" type="submit" disabled={loading} >
                        Submit Now{" "}
                        <img src="./images/arrow-right.png" alt="" />
                        </button>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />               
        {/* <section class="map-wrapper section-spacing container pt-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.1188409099286!2d73.1239546!3d33.55028849999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfed04d81818a1%3A0xecfa8857f30563f7!2sCivic%20Center%20Bahria%20Town%20Civic%20Center%20Bahria%20Town%2C%20Rawalpindi%2C%20Punjab!5e0!3m2!1sen!2s!4v1674895845295!5m2!1sen!2s" width="100%" height="550" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section> */}

    </div>

  )
}
