import React from 'react'
import { Link } from 'react-router-dom'

export default function ContactBanner() {
  return (
    <section className="contact-us-cta section-spacing">
        <div className="contact-us-cta-inner container flex-between">
        <div>
            <h6>LET US HELP</h6>
            <h1>Find the best service for you</h1>
        </div>
        <div>
            <Link to="/contact-us">
            Contact Us{" "}
            <img
                src="./images/arrow-right-blue.png"
                className="ms-2 pb-1"
                width="25px"
                alt=""
            />
            </Link>
            <a className="" href="tel:+923099077000">
            {" "}
            <img src="./images/phone-white.png" width="20px" alt="" /> +92
            309 9077000
            </a>
        </div>
        <img
            src="./images/shape-21.png"
            className="contact-us-cta-img-1"
            alt=""
        />
        <img
            src="./images/lamp.png"
            width="60px"
            alt=""
            className="floating-lamps"
        />
        <img
            src="./images/shape-24.png"
            className="contact-us-cta-img-2"
            alt=""
        />
        </div>
    </section>
  )
}
