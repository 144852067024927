import React from 'react'
import './Success.css'
import { Link } from 'react-router-dom'
export default function Success() {
  return (
    <div className='success-page'>
        <img src="./images/logo-white.png" width={'200px'} alt="" />
        <h1 className='my-4'>Form Submitted Successfully</h1>
        <Link to='/'>Return To HomePage</Link>
    </div>
  )
}
