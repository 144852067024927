import React from 'react'
import './About.css'
import ContactBanner from '../../components/ContactBanner/ContactBanner'
import StatCards from '../../components/StatCards/StatCards'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function About() {
  return (

        <div className="site-wrapper about-us-page">

            <Helmet>
                <title>About Us || Transformatics</title>
                <meta name="description" content="We are a Training and Consulting firm, help to drive value from Data &amp; AI with focus on the sub domains including EDW | BI | Analytics | Big Data | Data Science | AI." />
            </Helmet>

            <section className="about-us section-spacing">
                <div className=" about-us-inner container ">
                <div className="row ">
                    <div className="col-lg-6">
                    <div className="section-heading ">
                        <h6>ABOUT US</h6>
                        <h1>
                        Creating A Community Of Life <br className="d-none d-xl-block" />{" "}
                        Long Learners
                        </h1>
                    </div>
                    <div className="about-us-content">
                        <p>
                        We’re a Training and Consulting firm, help to drive value from
                        Data &amp; AI with focus on the sub domains including but not
                        limited to EDW | BI | Analytics | Big Data | Data Science | AI.
                        Highly Skilled and experience team helping people to learn and
                        drive their careers in right direction.
                        </p>
                        <div className="about-us-feature-list  gap-4 mt-5">
                        <div className="flex-center justify-content-start">
                            <img
                            src="./images/tick.png"
                            className="me-3"
                            width="24px"
                            alt=""
                            />{" "}
                            <span>Technology-driven efficiency</span>
                        </div>
                        <div className="flex-center justify-content-start">
                            <img
                            src="./images/tick.png"
                            className="me-3"
                            width="24px"
                            alt=""
                            />{" "}
                            <span>Experienced consultants</span>
                        </div>
                        <div className="flex-center justify-content-start">
                            <img
                            src="./images/tick.png"
                            className="me-3"
                            width="24px"
                            alt=""
                            />{" "}
                            <span>Customized solutions</span>
                        </div>
                        <div className="flex-center justify-content-start ">
                            <img
                            src="./images/tick.png"
                            className="me-3"
                            width="24px"
                            alt=""
                            />{" "}
                            <span>Valuable insights</span>
                        </div>
                        </div>
                        <div className="about-us-content-footer d-flex align-items-center">
                        <Link to="/contact-us" className="generic-btn-1">
                            Our Rep Can Help You{" "}
                            <img
                            src="./images/arrow-right.png"
                            width="25px"
                            alt=""
                            />
                        </Link>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="phone-logo-wrapper ms-5">
                            <img src="./images/phone.png" width="19px" alt="" />
                            </div>
                            <a
                            href="tel:+923099077000"
                            className="ms-2 fw-bold text-purple"
                            >
                            (+92) 309 9077000
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 about-us-img-wrapper px-5 flex-center">
                    <img src="./images/collage.avif" width="100%" alt="" />
                    </div>
                </div>
                </div>
            </section>


            <section className="vision section-spacing pt-0">
                <div className="vision-inner ">
                <div className="vision-statement-parent ">
                    <div className="row">
                    <div className="col-12 ">
                        <div className="container" style={{ position: "relative" }}>
                        <div className="vision-shape-1">
                            <img
                            src="./images/shape-04-01.png"
                            width="90px"
                            alt=""
                            />
                        </div>
                        <div className="vision-shape-2" />
                        <div className="section-heading">
                            <h6>OUR MISSION</h6>
                            <h1>
                            The Goal Behind <br className="d-none d-xl-block" />{" "}
                            Transformatics
                            </h1>
                            <p>
                            When fresh grads pass out and apply in the industry they lack
                            the knowledge and understanding of the tools and technologies
                            comonly used in the industry, hence they struggle.
                            Organizations which hire fresh grads spend time and money to
                            train these resources on the tools and technologies after
                            which the indiviual starts to be productive.
                            <br /> <br />
                            Transformaitcs bridges this gap and takes fresh grads and
                            train them on the popular tools and technologies along with
                            industry understanding so once these individuals apply for
                            jobs they are well versed with what technologies are used and
                            how they are used.
                            <br /> <br />
                            Transformatics Pvt Ltd is focused on bridging the gap between
                            Academia and Industry. We provide trainings on all the
                            relevant tools and technologies. These trainings are not just
                            academic trainings instead we use real world examples to train
                            these resources on what real world problems they will be
                            solving. Along with this we also provide in-depth
                            understanding of the industry and its challenges and how these
                            tools help solve those problems.
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="vision-image-grid-parent">
                        <div className="container">
                            <div className="vision-image-grid ">
                            <img
                                src="./images/founder-4.avif"
                                width="100%"
                                alt=""
                            />
                            <img
                                src="./images/founder-2.avif"
                                width="100%"
                                alt=""
                            />
                            <img
                                src="./images/founder-3.avif"
                                width="100%"
                                alt=""
                            />
                            <img
                                src="./images/founder-1.avif"
                                width="100%"
                                alt=""
                            />
                            </div>
                        </div>
                        </div>
                        <div className="container">
                        <div className="section-heading mt-5 pt-4">
                            <h6>OUR VISION</h6>
                            <h1>
                            The Story of <br className="d-none d-xl-block" />{" "}
                            Transformatics
                            </h1>
                            <p>
                            Transformatics Pvt Ltd was established in 2020 with a vision
                            to bridge the gap between academia and the industry.
                            <br /> <br />
                            We specialize in providing a range of services including
                            high-quality trainings and IT consultancy to individuals and
                            businesses looking to expand their knowledge and skills in the
                            technology industry. Our team of experienced instructors is
                            dedicated to delivering engaging and effective training
                            programs that cover a wide range of topics, from programming
                            languages to network security. In addition to training, our
                            consultancy services can help your business with IT strategy,
                            project management, system design and implementation, and
                            more. <br /> <br /> Our mission is to empower our clients with
                            the knowledge, skills, and expert guidance necessary to
                            succeed in a constantly evolving industry.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="founder section-spacing pt-0">
                <div className="container">
                    <div className="section-heading mb-5">
                        <h6>OUR FOUNDERS</h6>
                        <h1>About Our Founders</h1>
                    </div>
                    <div className="founder-card-wrapper row ">
                        <div className="founder-card col-md-6 ">
                        <a
                            className="founder-img-wrapper"
                            href="https://www.linkedin.com/in/nauman-mir-1b593285/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="founder-img-inner">
                            <img src="././images/instructor-1.png" alt="" />
                            </div>
                        </a>
                        <h3 className="mt-4">Nauman Mir</h3>
                        <h5 className="text-blue">Data Analytics Expert</h5>
                        <h6 className="mt-1 mb-4">
                            15+ Years of Experience in Analytics Industry
                        </h6>
                        <a
                            className="social-wrapper"
                            href="https://www.linkedin.com/in/nauman-mir-1b593285/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                            src="././images/linkedin-blue.png"
                            width="20px"
                            alt=""
                            />
                        </a>
                        </div>
                        <div className="founder-card col-md-6 ">
                        <a
                            className="founder-img-wrapper"
                            href="https://www.linkedin.com/in/ammad-tahir-a3687685/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="founder-img-inner">
                            <img src="././images/ammad-center.avif" alt="" />
                            </div>
                        </a>
                        <h3 className="mt-4">Ammad Tahir</h3>
                        <h5 className="text-blue">Senior BI Consultant </h5>
                        <h6 className="mt-1 mb-4">
                            10+ Years of Experience in Analytics Industry
                        </h6>
                        <a
                            className="social-wrapper"
                            href="https://www.linkedin.com/in/ammad-tahir-a3687685/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                            src="././images/linkedin-blue.png"
                            width="20px"
                            alt=""
                            />
                        </a>
                        </div>
                    </div>
                </div>
            </section>

            <ContactBanner/>
            
        </div>

  )
}
