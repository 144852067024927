import React from 'react'
import './Testimonials.css'
import LazyLoad from 'react-lazyload';
import Slider from "react-slick";
import ReactPlayer from 'react-player/youtube';

export default function Testimonials() {

    let videoSettings = {
        autoplay : false ,
        arrows : true ,
        responsive : true ,
        slidesToShow : 3 ,
        swipeToSlide : true ,
        nextArrow : <button type="button" class="slick-next"><img src = "./images/chevron-right.png" /></button> ,
        prevArrow : <button type="button" class="slick-prev"><img src = "./images/chevron-left.png" /></button> ,
  
        responsive: [
  
          {
        
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            }
      
          } ,
  
          {
    
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
    
        }, {
    
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          }
    
        }]
  
  
    }

  return (
    <LazyLoad once height={200}>
        <section className="success-stories section-spacing">
            <div className="container">
            <div className="section-heading ">
                <h6 className="text-orange">Testimonials</h6>
                <h1 className="text-white">Success Stories</h1>
            </div>
            <div className="video-slider-wrapper  mt-5">
                <Slider {...videoSettings} >
                    <div className="video-item-wrapper">
                        <ReactPlayer url="https://www.youtube.com/embed/UPGvFj3JpiY" controls={true} />
                    </div>
                    <div className="video-item-wrapper">
                        <ReactPlayer url="https://www.youtube.com/embed/gTudPcB7mDs" controls={true}/>
                    </div>
                    <div className="video-item-wrapper">
                        <ReactPlayer url="https://www.youtube.com/embed/GzaKdqb8tL0" controls={true}/>
                    </div>
                </Slider>
            </div>
            </div>
        </section>
    </LazyLoad>
  )
}
