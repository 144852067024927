import './App.css';
import './index.css'
import { Routes , Route , ScrollRestoration } from 'react-router-dom'
import Home from './pages/Home/Home';
import About from './pages/About/About'
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';
import NotFound from './pages/NotFound/NotFound';
import React from 'react';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Training from './pages/Training/Training';
import Contact from './pages/Contact/Contact';
import Success from './pages/Success/Success';
// import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// import AlertTemplate from 'react-alert-template-basic'
import Instructors from './pages/Instructors/Instructors';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Course from './pages/Course/Course';
import PricePlan from './pages/PricePlan/PricePlan';
import Consultancy from './pages/Consultancy/Consultancy';
import Counselling from './pages/Counselling/Counselling';
import CorporateCourse from './pages/CorporateCourse/CorporateCourse';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics';



function App() {

  
  return (
    <div className="App">
      <ScrollToTop/>
      <Header/>
      {/* <AlertProvider template={AlertTemplate} {...options}> */}
        <Routes>

          <Route Component={GoogleAnalytics}>

            <Route Component={Home} path='/' exact />

            <Route Component={About} path='/about-us' exact />

            <Route Component={Contact} path='/contact-us' exact />

            <Route Component={Training} path='/trainings' exact />

            <Route Component={Course} path='/trainings/:slug' exact />

            <Route Component={CorporateCourse} path='/corporate-trainings/:slug' exact />

            <Route Component={Instructors} path='/instructors' exact />

            <Route Component={Consultancy} path='/consultancy' exact />

            <Route Component={Counselling} path='/counselling' exact />

            <Route Component={PricePlan} path='/price-plan' exact />

            <Route Component={Success} path='/POST'  />

            <Route Component={NotFound} path='*' exact />

          </Route>
        </Routes>
      {/* </AlertProvider> */}
      <Footer/>
      <WhatsAppButton/>
    </div>
  );
}

export default App;
