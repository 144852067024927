const  instructors = [
    {
        name : 'Nouman Mir' ,
        title : 'Data Analytics Expert' ,
        linkedin : "https://www.linkedin.com/in/nauman-mir-1b593285/" ,
        imgSrc : './images/instructor-1.png'
    } ,
    {
        name: 'Ammad Tahir',
        title: 'DWH & BI Expert',
        linkedin: 'https://www.linkedin.com/in/ammad-tahir-a3687685/',
        imgSrc: './images/ammad-center.avif'
    },
    {
        name: 'Umar Afzal',
        title: 'Senior IT & Telecom Executive',
        linkedin: 'https://www.linkedin.com/in/umerafzal/',
        imgSrc: './images/umer-afzal.jpg'
    },
    {
        name: 'Bilal Bin Munir',
        title: 'Analytics Ecosystem Advisor',
        linkedin: '',
        imgSrc: './images/bilal-bin-munir.avif'
    } ,
    {
        name: 'Salman Faiz Cheema',
        title: 'Business Analytics Expert',
        linkedin: 'https://www.linkedin.com/in/salman-faiz-cheema-447bba4/',
        imgSrc: './images/instructor-5.jpg'
    } ,
    {
        name: 'Saba Farooqi',
        title: 'BI-Modeling & Reporting Expert',
        linkedin: 'https://www.linkedin.com/in/saba-farooqi-44b88121b/',
        imgSrc: './images/instructor-4.png'
    } ,
    {
        name: 'Umer Ali',
        title: 'Big Data & DWH Expert',
        linkedin: 'https://www.linkedin.com/in/mumerali/',
        imgSrc: './images/instructor-6.jpg'
    },
    {
        name: 'Yasir Mehmood',
        title: 'SQL & Power-BI Expert',
        linkedin: 'https://www.linkedin.com/in/yasir-mahmood-b42324132/',
        imgSrc: './images/instructor-7.jpg'
    },
    {
        name: 'Umer Munir',
        title: 'Cloud & DevOps Expert',
        linkedin: 'https://www.linkedin.com/in/umer-munir-1a28a656/',
        imgSrc: './images/instructor-8.jpg'
    } ,
    // {
    //     name: 'Asim Rashid',
    //     title: 'Cloud & BigData Expert',
    //     linkedin: '',
    //     imgSrc: './images/instructor-9.jpg'
    // },
    // {
    //     name: 'Omer Iftikhar',
    //     title: 'Omer Iftikhar',
    //     linkedin: 'https://www.linkedin.com/in/umer-i-774b866/',
    //     imgSrc: './images/umer.jpeg'
    // },
    // {
    //     name: 'Saad Waseem',
    //     title: 'BigData and DWH Expert',
    //     linkedin: 'https://www.linkedin.com/in/mswaseem/',
    //     imgSrc: './images/instructor-11.jpg'
    // },
    // {
    //     name: 'Wasif Kamal',
    //     title: 'MSTR Expert',
    //     linkedin: 'https://www.linkedin.com/in/wasifkamal/',
    //     imgSrc: './images/instructor-12.jpg'
    // },
    {
        name: 'Hajra Asif',
        title: 'Power-BI & Tableau Expert',
        linkedin: 'https://www.linkedin.com/in/hajirah-asif-513735147',
        imgSrc: './images/instructor-13.jpg'
    },
    {
        name: 'Syed Abbas Rizvi ',
        title: 'Data Engineer ',
        linkedin: 'https://www.linkedin.com/in/muhammadrizvi/',
        imgSrc: './images/syed-abbas.jpg'
    }, 
    {
        name: 'Usman Jamal',
        title: 'Sr. Data Engineering Specialist ',
        linkedin: 'https://www.linkedin.com/in/usman-jamal-b9308473/',
        imgSrc: './images/usman-jamal.jpg'
    }, 
    {
        name: 'Rahim Nawaz Khan',
        title: 'Legal, Corporate and Tax Advisor ',
        linkedin: 'https://www.linkedin.com/in/rahim-nawaz-khan-30075849/',
        imgSrc: './images/rahim-nawaz.jpg'
    }, 
    {
        name: "Faraz Shahid" , 
        title: "Senior Data Scientist" , 
        linkedin : "https://www.linkedin.com/in/fshahid/" ,
        imgSrc: "./images/instructor-16.avif"
    } , 
    {
        name: "Usman Shahrukh" , 
        title: "Senior Data Scientist / Analyst" , 
        linkedin : "https://www.linkedin.com/in/usmanshahrukh/" ,
        imgSrc: "./images/instructor-17.jpg"
    } ,

    {
        name: "Haroon Latif" , 
        title: "Business Analyst" , 
        linkedin : "https://www.linkedin.com/in/h-latif/" ,
        imgSrc: "./images/haroon-latif.jpg"
    }

    // {
    //     name: 'Awais Ejaz',
    //     title: 'Data Analytics Architect',
    //     linkedin: '',
    //     imgSrc: './images/instructor-14.jpg'
    // },
    // {
    //     name: 'Muhammad Mehmood',
    //     title: 'BigData Expert',
    //     linkedin: '',
    //     imgSrc: './images/instructor-15.jpg'
    // }    
]

export default instructors