import React , {useEffect} from 'react'
import AOS from 'aos'
import ContactBanner from './../../components/ContactBanner/ContactBanner'

export default function Consultancy() {

    useEffect( () => {
        AOS.init({ once : true });
    } , [] )

  return (
    <div className='consultancy-page'>
        <section className="offer section-spacing">
            <div className="offer-inner container">
                <div className="section-heading text-center my-5 ">
                    <h6>Our Services</h6>
                    <h1>What We Offer</h1>
                </div>
                <div className="offer-card-grid mt-5">
                    <div className="flip-card" data-aos="fade-right">
                    <div className="flip-card-inner">
                        <div className="flip-card-front py-5">
                        <div className="offer-card-img-wrapper">
                            <img src="./images/analysis.png" width="45px" alt="" />
                        </div>
                        <h5>Business Process Analysis</h5>
                        </div>
                        <div className="flip-card-back py-5">
                        <p>
                            We evaluate existing business processes and identify areas for
                            automation, optimization, and standardization to improve
                            efficiency.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="flip-card" data-aos="fade-in" data-aos-duration={1000}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front py-5">
                        <div className="offer-card-img-wrapper">
                            <img
                            src="./images/infrastructure.png"
                            width="40px"
                            alt=""
                            />
                        </div>
                        <h5>IT Infrastructure Assessment</h5>
                        </div>
                        <div className="flip-card-back py-5">
                        <p>
                            We assess clients' IT infrastructure and recommend upgrades,
                            enhancements, or system replacement for better performance and
                            security.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="flip-card" data-aos="fade-in" data-aos-duration={1000}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front py-5">
                        <div className="offer-card-img-wrapper">
                            <img
                            src="./images/data-analytics.png"
                            width="44px"
                            alt=""
                            />
                        </div>
                        <h5>Data Analytics</h5>
                        </div>
                        <div className="flip-card-back py-5">
                        <p>
                            We help organizations collect, process, and analyze data from
                            various sources to gain insights into business operations,
                            customer behavior, market trends, and more.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="flip-card" data-aos="fade-left">
                    <div className="flip-card-inner">
                        <div className="flip-card-front py-5">
                        <div className="offer-card-img-wrapper">
                            <img src="./images/BI.png" width="44px" alt="" />
                        </div>
                        <h5>Business Intelligence</h5>
                        </div>
                        <div className="flip-card-back py-5">
                        <p>
                            We design and implement data-driven solutions that provide
                            insights and actionable intelligence to support better
                            decision-making.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div
                    className="offer-card-dummy d-none d-xl-block"
                    data-aos="fade-in"
                    data-aos-duration={1000}
                    />
                    <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front py-5">
                        <div className="offer-card-img-wrapper">
                            <img src="./images/software.png" width="40px" alt="" />
                        </div>
                        <h5>Custom Software</h5>
                        </div>
                        <div className="flip-card-back py-5">
                        <p>
                            We develop customized software solutions that meet the unique
                            needs of our clients and integrate with their existing systems
                            seamlessly.
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="flip-card" data-aos="fade-in" data-aos-duration={1000}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front py-5">
                        <div className="offer-card-img-wrapper">
                            <img src="./images/services.png" width="44px" alt="" />
                        </div>
                        <h5>Managed Services</h5>
                        </div>
                        <div className="flip-card-back py-5">
                        <p>
                            Our end-to-end DevOps and managed services allow clients to free
                            up their resources for other tasks while our experts manage
                            their day-to-day operations.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <section className="offer section-spacing  bg-light" >
            <div className="offer-inner container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-heading text-left mb-5 ">
                            <h6>Our Services</h6>
                            <h1>Smart Solutions</h1>
                            <p className='text-justify mt-4 mb-5'>We provide Outsourced IT Services For your business with high-skilled, smart and innovative solutions to fit your needs. Our experts have a vast experience and extensive knowledge and understanding of various tools and tricks.
                            Contact us now to get the best solutions the market has to offer.</p>
                            <Link className="generic-btn-1 w-md-50" style={{'width':'180px'}}  to={'/contact-us'}>Contact Us</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 align-items-center px-5 p-lg-0">
         
                        <img src="/images/consult-2.png" width={'100%'} alt="" />
    
                    </div>
                </div>
            </div>
        </section> */}
        <ContactBanner/>
    </div>
  )
}
