const corporateTrainings = [
    {
        slug : 'power-bi' ,
        name : 'Power BI'
    } ,
    {
        slug : 'microstrategy' ,
        name : 'Microstrategy' 
    } ,
    {
        slug : 'odi' ,
        name : 'Oracle Data Integrator' 
    } ,
    {
        slug : 'oas' ,
        name : 'Oracle Analytics Server'
    }
]

export default corporateTrainings 