const trainings = [
    //DATA SCIENCE
    {
        slug : 'data-science' ,
        name : 'Data Science & Machine Learning' ,
        duration : "3 months" ,
        hours : 72 ,
        fee : 40000 ,
        imgSrc : '/images/ds-ml-cta.webp' ,
        formLink : 'https://forms.gle/rCqvYBdFGbno8j6b9',
        description : [
            "This curriculum is designed for audience who knows nothing or very little about Data Science or related subject and are interested to take their first step towards learning this vast field." 
        ] ,

        learningObjectives : [
            
            "The basics of statistics and probability, including descriptive statistics, probability distributions, hypothesis testing, and regression analysis.",

            "The fundamentals of programming with Python, including data structures, control structures, functions, and object-oriented programming.",

            "How to use Python for data manipulation, analysis, and visualization.",

            "The concept of vectors and how they are used in data science and machine learning, including vector operations, vector spaces, and linear algebra.",

            "The basics of SQL, including database design, querying, and data manipulation using SQL commands.",

            "How to use machine learning algorithms such as decision trees, logistic regression, and k-nearest neighbors for classification and regression tasks.",

            "How to evaluate machine learning models using performance metrics such as accuracy, precision, recall, and F1 score.",

            "How to build and deploy machine learning models using popular frameworks."
        ] ,
        curriculum : [

            {
                week : "Week 1" ,
                topics : [
                    {
                        title : "Introduction to Data, Data Science and Statistics" ,
                        subTopics : [
                            "Data Science Fields",
                            "Relationship between different Data Science Fields",
                            "Purpose of each Data Science Field",
                            "Common Data Science Techniques",
                            "Common Data Science Tools",
                            "Data Science Jobs: Expectation",
                            "Dispelling common Misconception",
                        ]
                    } ,
    
                    {
                        title : 'Statistics' ,
                        subTopics : [
                            "Introduction to Statistics",
                            "Descriptive Statistics Fundamentals",
                            "Inferential Statistics Fundamentals",
                            "Confidence Intervals",
                            "Hypothesis Testing",
                            "Case Study",
                        ] ,
                    } 
                ] ,

                summary : "This week begins with the very basics of data science and statistics and builds up your arithmetic thinking. It gradually teaches you how to work with more complex analysis, statistical approaches, and hypothesis."
            } ,
            
            {
                week : "Week 2" ,
                topics: [
                  {
                    title: "Probability",
                    subTopics: [
                      "Basics of Probability",
                      "Combinatorics",
                      "Bayesian Inference",
                      "Discrete Distributions",
                      "Continuous Distributions",
                      "Probability in Other Fields",
                      "Hands-on Assignment",
                      "Case Study"
                    ]
                  }
                ]
            } ,

            {
                week : "Week 3" ,
                topics: [
                  {
                    title: "Mathematics",
                    subTopics: [
                      "Matrix",
                      "Scalars",
                      "Vectors",
                      "Linear Algebra and Geometry",
                      "Eigen Values and Eigen Vector",
                      "Dimensionality Reduction",
                      "Principal Component Analysis",
                      "Linear Discriminant Analysis"
                    ]
                  },
                  {
                    title: "SQL",
                    subTopics: [
                      "Introduction",
                      "DDL",
                      "DML",
                      "Aggregates",
                      "Joins",
                      "Subqueries",
                      "Best Practices",
                      "Hands-on assignment"
                    ]
                  }
                ],
                summary: "This module will teach you the mathematical fundamentals used in the fields of data science by covering topics of calculus and linear algebra. It also delivers the numerical tools required for applying mathematical knowledge into practice. Also, SQL is a must if you are expected to work with databases. This module is a guide, teaching you everything you need to know in terms of database management and creating SQL queries as a Data Scientist."
            } ,

            {
                week : "Week 4" ,
                topics: [
                  {
                    title: "Python",
                    subTopics: [
                      "Python Basics",
                      "Introduction to Jupyter Notebook",
                      "Variables and Strings",
                      "Conditions",
                      "Loops",
                      "Dictionaries",
                      "Classes",
                      "Optimized programming for Data Science",
                      "Visualization",
                      "Pandas",
                      "Hands-on Lab"
                    ]
                  }
                ],
                summary: "The module is the ideal tool to further improve your Python skills. The course will guide you through some of the basics, but also introduce some advanced techniques used with this programming language."
            } ,
            
            {
                week : "Week 5" ,
                topics: [
                  {
                    title: "Business Analytics",
                    subTopics: [
                      "Understanding Business",
                      "End-to-End processes in an organization",
                      "Targets",
                      "Maturity Stages in Analytics",
                      "Analytics Techniques in Practice",
                      "Analytics Lifecycle",
                      "Data Visualization for Business Analytics",
                      "Hands-on Analysis",
                      "Case Study"
                    ]
                  }
                ],
                summary: "Do you want to know how worldwide top business corporations build and use data-based decision-making systems to succeed? This module will guide you through the business analytics fundamentals and importance of data to make informed decisions."
            } ,

            {
                week : "Week 6 and 7" ,
                topics: [
                  {
                    title: "Machine Learning",
                    subTopics: [
                      "Exploratory Data Analysis",
                      "Data Preprocessing and Feature Engineering",
                      "Linear Regression and Implementation",
                      "Logistic Regression",
                      "Cluster Analysis",
                      "K-Means Clustering",
                      "Decision Trees & Random Forest",
                      "Naïve Bayes",
                      "SVM",
                      "Business Cases Studies",
                      "Introduction to Deep Learning",
                      "Project Assignments",
                      "Hands-on Labs",
                      "Project Kick-Offs"
                    ]
                  }
                ],
                summary: "These weeks are focused on predictive modelling via an array of approaches such as linear regression, logistic regression, and cluster analysis. It combines comprehensive theory with lots of practice to allow you to exercise your Python skills."
            }
              ,
            {
                week : "Week 8 and 9" ,
                topics: [
                  {
                    title: "Group Discussion",
                    subTopics: [
                      "Peer Reviews",
                      "Consulting Basics"
                    ]
                  }
                ]
            }
              
              
              

        ] ,

        instructors : [
            {
                name : 'Faraz Shahid' ,
                title : 'SOFTWARE ARCHITECT - DATA SCIENCE AT NORTHBAY SOLUTIONS' ,
                linkedin : "https://www.linkedin.com/in/fshahid/" ,
                imgSrc : '/images/instructor-16.avif' ,
                description : "A Data Science Consultant with over 13 years of experience in successfully delivering analytics and information systems solutions/projects to leading telecommunications, retail, FMCG and banks across Pakistani, MEA and North American markets. He has led the successful inception, design and execution of advanced analytics, business operations and managed services projects covering different industries. His recent experience includes delivering use cases like market basket analysis, people analytics, performance prediction, customer experience and path analytics. He has also held technical lead roles on several data science and managed services projects in a multi-platform ecosystem environment." 
            } ,
            {
                name: "Usman Shahrukh",
                title: "SENIOR DATA SCIENTIST AT JAZZ",
                linkedin: "https://www.linkedin.com/in/usmanshahrukh/",
                imgSrc: "/images/instructor-17.jpg",
                description: "He has 11 Years of experience in the domain of Data Science and Business Analytics. He has worked in Telecommunication, Pharmaceutical, Banking, Retail industry and have implemented Data Science use cases in Pakistan, Sweden and Turkey. He has also worked in Commercial Analytics in USA and as a Business Intelligence Consultant in Sweden and Switzerland."
              }
              
        ]
    } 
    ,
    //BIG DATA
    {
        slug : 'big-data' ,
        name : 'Big Data Hands-on Training' ,
        duration : "3 months" ,
        hours : 72 ,
        fee : 40000 ,
        imgSrc : "/images/big-data-cta.avif" ,
        formLink : 'https://forms.gle/1ng4pjmVyqJZFjoc6',
        description: [
            "Offering a 7-week extensive hands-on training on big data analytics that will allow learners to develop real-time and batch pipelines to satisfy the analytics industry's requirements for better decision making."
          ],
          
          learningObjectives: [
            "The fundamental concepts and principles of Big Data, including the three Vs: Volume, Velocity, and Variety.",
            "The architecture of Hadoop, including the various components and their functions, such as HDFS (Hadoop Distributed File System), MapReduce.",
            "How to work with Hadoop distributed file system (HDFS), including copying, moving, and deleting files, and reading and writing data to HDFS.",
            "How to use Hive, higher-level languages built on top of Hadoop, to process data and run queries.",
            "How to use Spark, a fast and flexible data processing engine, to perform data analysis and processing.",
            "How to use Apache Kafka, a distributed streaming platform, for real-time data processing and stream processing.",
            "Implement a project using Kafka, enrich using Spark.",
            "Implement the same project using Scala and submit it using SBT for build."
          ] ,

          curriculum: [
            {
              week: "Week 1",
              topics: [
                {
                  title: "Intro to Big Data and Hadoop",
                  subTopics: [
                    "Intro to BigData and Hadoop",
                    "Setup - HDP or CDP or Docker setup for Zeppelin",
                    "HDFS Theory, Familiarity/Lab with Ambari or Cloudera manager / Zeppelin",
                    "HDFS Theory, Familiarity/Lab with Ambari or Cloudera manager / Zeppelin",
                    "MapReduce theory"
                  ]
                }
              ],
              summary: "By the end of the week, the candidate has a basic understanding of Big Data and Hadoop"
            },
            {
              week: "Week 2",
              topics: [
                {
                  title: "Batch Processing Engines",
                  subTopics: [
                    "Spark Theory - RDDs",
                    "Spark Lab - RDDs",
                    "Hive basic theory / Lab",
                    "Hive advanced theory (partitioning / bucketing / delta files)",
                    "Hive advanced Lab"
                  ]
                }
              ],
              summary: "By the end of the week, the candidate has a basic understanding of batch processing engines."
            }
            ,
            {
                week: "Week 3",
                topics: [
                  {
                    title: "Advance Processing in Spark",
                    subTopics: [
                      "Spark Theory : Advanced RDD operations with accumulators",
                      "Spark Lab: Advanced RDD lab",
                      "Spark Theory: Dataframes",
                      "Spark Lab: Dataframes",
                      "Spark Tuning"
                    ]
                  }
                ],
                summary: "By the end of the week, the candidate has expertise in Spark to meet the real-time needs of an industry."
              },
              {
                week: "Week 4",
                topics: [
                  {
                    title: "Streaming Data Processing Engines",
                    subTopics: [
                      "Minor theory revision (Spark+MR) / Sqoop lab",
                      "Kafka theory",
                      "Kafka lab (CLI based)",
                      "Spark streaming theory",
                      "Spark streaming lab utilizing Kafka and HDFS"
                    ]
                  }
                ],
                summary: "By the end of the week, the candidate has expertise in streaming data processing engines."
              } ,
              {
                week: "Week 5",
                topics: [
                  {
                    title: "In Memory VS Disk Storage",
                    subTopics: [
                      "HBase Theory",
                      "HBase Lab",
                      "HBase Usage lab with Spark Streaming",
                      "Ranger Theory / Lab",
                      "Project Intro / Assigment of Public API discovery"
                    ]
                  }
                ],
                summary: "By the end of the week, the candidate has expertise in In-Memory and disk storage."
              },
              {
                week: "Week 6",
                topics: [
                  {
                    title: "Project 1: Streaming Data and Ingestion in Spark",
                    subTopics: [
                      "Project: Ingest data from a public API of the student's choice, write on Kafka topic. Then retrieve the same data from Kafka and enrich using Spark streaming. Write enriched output in Hive table. Practice Hive compaction on delta files created as project output."
                    ]
                  }
                ],
                summary: "By the end of the week, the candidate has end-to-end experience in building big data pipelines for analytics."
              },
              {
                week: "Week 7",
                topics: [
                  {
                    title: "Project 2: Streaming Data and Ingestion in Scala",
                    subTopics: [
                      "Re-implement/migrate exactly the same project (week 6) in Scala and build and submit it using SBT for build",
                      "Career counseling & Final Assessment"
                    ]
                  }
                ],
                summary: "By the end of the week, the candidate has end-to-end experience in building big data pipelines for analytics."
              }
          ] ,

          instructors : [
            {
                name: "Muhammad Umer Ali",
                imgSrc: "/images/instructor-6.jpg",
                title: "SENIOR BIG DATA ENGINEER AT IBM",
                linkedin: "https://www.linkedin.com/in/mumerali/",
                description: "He has 10+ years of experience in the field of Data Warehouse, Big Data and Cloud. He is an expert in Enterprise Data warehouses, Implementation of Big Data Applications and Hybrid ETL Architecture. Some of his other specialties include Data Modelling, Python, C/C++ & Java, Unix/Linux environments, shell scripting and Cluster based hardware/software design - MPP and SMP systems (MPI, OpenMPI, CUDA)."
            }
          ]
          
    }
    ,
    //BUSINESS INTELLIGENCE 
    {
        slug : 'business-intelligence' ,
        name : 'Business Intelligence' ,
        duration : "5 weeks" ,
        hours : 40 ,
        fee : 30000 ,
        sideImg : '' ,
        formLink : 'https://forms.gle/8L98oadjtoagXrSB9',
        imgSrc : "/images/bi-cta.avif" ,
        description: [
            "Offering a 5-week hands-on training, Reporting and visualization of data to get the complete insights of business for better decision making.", "Participants will master business intelligence using the top tools of BI; Power BI, Tableau, Excel and SQL in order to support the business in a better and structured way."
        ],
        learningObjectives: [
          "The fundamental concepts and principles of business intelligence, such as data warehousing, ETL (extract, transform, load), data visualization, and data analysis.",
          "How to model data for BI purposes, including designing dimensional data models and implementing data hierarchies.",
          "How to write SQL queries to extract data from relational databases, perform joins, and aggregate data.",
          "How to create ad hoc reports using tools such as Excel and Power BI, including creating pivot tables, charts, and graphs.",
          "How to use Power BI and Tableau to create interactive dashboards and visualizations, including designing layouts, selecting appropriate visualizations, and applying filters and slicers.",
          "How to use data analytics techniques such as clustering, segmentation, and regression to gain insights from data.",
          "How to present findings and insights to stakeholders effectively, including creating reports, presentations, and dashboards that communicate insights clearly and accurately."
        ] ,
        curriculum : [
            {
                "week": "Week 1",
                "topics": [
                  {
                    "title": "Introduction to BI & Data Modelling",
                    "subTopics": [
                      "Introduction to Data Warehouse & BI",
                      "Business Intelligence & its importance",
                      "OLAP – Dimensional Modelling Fundamentals",
                      "Dimensional Modelling Design with industrial use case",
                      "Build & deploy Semantic layer",
                      "Design Steps – Kimball’s Dimensional Modeling (Hands on)",
                      "Implementation – Kimball’s Dimensional Modeling (Hands on)",
                      "Managing DWH history through Slowly Changing Dimensions (SCDs) with use cases",
                      "ROLAP vs MOLAP vs HOLAP",
                      "Design & implementation of Kimball’s Dimensional Model technique – Semantic layer Readiness"
                    ]
                  }
                ],
                "summary": "By the end of this course, the candidate should have a complete understanding of BI & Data Modelling"
              } ,
              {
                "week": "Week 2 and 3",
                "topics": [
                  {
                    "title": "SQL Ad-hoc Reporting & Analysis",
                    "subTopics": [
                      "Introduction to SQL – Query language for Data Warehouse",
                      "DDL vs. DML vs. DCL",
                      "Creating Databases, Tables & defining attributes",
                      "BTEQs & Table types – Set vs Multiset",
                      "Views / Materialized views",
                      "Stored Procedures & Functions",
                      "Indexing & Constraints",
                      "Aggregate functions, Window aggregate functions, Order analytical function"
                    ]
                  },
                  {
                    "title": "Microsoft Excel",
                    "subTopics": [
                      "Loading, Cleaning & Preparing data",
                      "Managing the Data Model",
                      "Pivot Table for Data Analysis",
                      "Lookup Functions, Aggregate Functions & Merging techniques",
                      "Data Analysis using Charts & Visualizations"
                    ]
                  },
                  {
                    "title": "Microsoft Power BI",
                    "subTopics": [
                      "Connect & Get Data from multiple Data sources",
                      "Data Connection/Storage modes – Import, Live, Direct Query & Dual",
                      "Reshaping and Transforming Data in Query Editor",
                      "Data Enrichment (New business Fields)",
                      "Data Modelling",
                      "Understanding Cardinalities",
                      "Understanding Filter Context",
                      "Building Interactive Visualizations on implemented Dimensional Model",
                      "Animated Visualization Implementation",
                      "Roll-up/Roll-Down Capabilities",
                      "Introduction to DAX Language",
                      "Custom visualization in Power BI",
                      "Creating DAX Measures",
                      "Evaluating DAX Measures",
                      "Leverage Calculate Functions functionality",
                      "Time Intelligence – MTD, QTD and YTD Date Calculations",
                      "Introduction to Power BI Services",
                      "Scheduling Automated Reports Refresh",
                      "Sharing Reports & Dashboards",
                      "Mobile Dashboard Design",
                      "Performance monitoring & debugging a slow running report – DAX Studio",
                      "Business Use Case implementation in Power BI (Assignment)"
                    ]
                  }
                ],
                "summary": "By the end of this course, the candidate should have a complete understanding of BI Reporting & Analysis"
              },
              {
                "week": "Week 4 and 5",
                "topics": [
                  {
                    "title": "Tableau",
                    "subTopics": [
                      "Connecting with Different Data Sources in Tableau",
                      "Data preparation with Tableau",
                      "Live Vs Extract",
                      "Data Source Filters",
                      "Basic Report Creation",
                      "Understanding of Rows and Columns",
                      "Leveraging the Use of Marks Labels to enrich information in Reports",
                      "Visualization best practices with real world examples",
                      "Grouping fields in Tableau",
                      "Interactive Filters",
                      "Types of filters",
                      "Advanced Filter Calculations",
                      "Enhancing user interactivity thorough parameters",
                      "Pages",
                      "Maps in Tableau",
                      "Importing custom geocoding in Tableau",
                      "Visualize your data on map through spatial files",
                      "Building a Dashboard",
                      "Leveraging the use of Interactivity in Dashboards through Actions",
                      "Designing and implementation of dashboard",
                      "Designing of dashboard for mobile & Tablets",
                      "Extensions",
                      "Enriching information by creating Calculated Fields",
                      "Calculation Syntax",
                      "Date/Logic/String Calculations",
                      "Advance Calculations (LODs)",
                      "LODs & real-world Use cases",
                      "Visual analytics",
                      "80-20 rule – Pareto Chart",
                      "Business Use Case implementation in Tableau (Assignment)",
                      "Career counseling & Final Assessment"
                    ]
                  }
                ],
                "summary": "By the end of this course, the candidate should have a complete understanding of Tableau"
              }
        ] ,
        instructors: [
            {
              name: "Saba Farooqi",
              imgSrc: "/images/instructor-4.png",
              title: "ASSOCIATE PRACTICE MANAGER, BUSINESS INTELLIGENCE AT TERADATA",
              linkedin: "https://www.linkedin.com/in/saba-farooqi-44b88121b/",
              description: "Saba Farooqi has over 13 years of extensive experience in Business Intelligence & Data warehousing solutions with a focus on access layer design and foundation layout. She has managed teams and created benchmarks in semantic data modeling in the telecom sector of Pakistan apart from ensuring on-time delivery of programs & projects as a product owner. With an emphasis on effective communication with stakeholders, she has expounded complex business objectives and product requirements to develop consensus over solutions and ensured customer satisfaction."
            },
            {
              name: "Yasir Mehmood",
              imgSrc: "/images/instructor-7.jpg",
              title: "BUSINESS INTELLIGENCE CONSULTANT AT TERADATA",
              linkedin: "https://www.linkedin.com/in/yasir-mahmood-b42324132/",
              description: "A Computer Science graduate by qualification and a developing data enthusiast by choice, Yasir Mehmood holds over 7 years of industry experience in the field of technology, data analytics, data warehousing, EL/TL, data mining, reporting & visualization. His qualifications & actual job in the field of Business Intelligence & Data Warehousing have provided him with a well-rounded background and enabled him to develop an analytical/logical approach to tasks, software skills, and the ability to work under pressure."
            }
          ]
          
    }
    ,
    //DWH-BI 
    {
      slug : 'dwh-bi' ,
      name : 'Data WareHouse and Business Intelligence' ,
      duration : "3 months" ,
      hours : 72 ,
      fee : 40000 ,
      imgSrc : "/images/dwh-bi-cta.avif" ,
      formLink : 'https://forms.gle/VK4BedhWWexJG7SAA',
      sideImg : '' , 

      description: [
        "Offering a 9-week hands-on training, enabling students to implement green field implementation of DWH to perform reporting and visualization (BI) for 360-degree corporate profiling using the industry standards. Participants will master data warehousing using SQL, Power BI, and Tableau for analysis and reporting after completing all phases comprising of business analyst, data modeler, ETL developer and BI expert."
      ],
      learningObjectives: [
        "The fundamental concepts and principles of data warehousing, including the differences between OLTP (online transaction processing) and OLAP (online analytical processing) systems.",
        "The architecture of a data warehouse, including the various components and their functions, such as ETL (extract, transform, load) processes, data modeling, and data integration.",
        "How to design and implement a data warehouse, including identifying data sources, defining a data model, and designing the ETL process.",
        "How to master ETL (extract, transform, load) processes, including identifying source systems, mapping data to the data warehouse model, and implementing transformations.",
        "How to use BI (business intelligence) data modeling techniques to design and build a data warehouse schema, including normalization, dimensions, fact tables, and hierarchies.",
        "How to use Excel and Power BI to analyze and visualize data from a data warehouse, including creating pivot tables, charts, and graphs.",
        "How to use Power BI to create interactive dashboards and visualizations, including designing layouts, selecting appropriate visualizations, and applying filters and slicers.",
        "How to use data warehousing best practices, such as data quality management, data governance, and data security.",
        "How to manage and maintain a data warehouse, including monitoring performance, ensuring scalability, and performing backups and recovery."
      ],

      curriculum: [
        {
          week: "Week 1",
          topics: [
            {
              title: "Introduction to Data Warehouse & Business Intelligence",
              subTopics: [
                "Introduction to Data Warehouse & BI",
                "Importance of a Data Warehouse & its applications",
                "Introduction to Data Warehouse core concepts",
                "Transactional databases vs. Data Warehouses",
                "Databases vs. RDBMS & its types",
                "Business Intelligence vs. Data Science vs. Data Engineering",
                "Normalization vs. Denormalization",
                "Introduction to Teradata TTUs",
                "VM & required SW Installations",
                "Introduction to SQL – Query language for Data Warehouse",
                "DDL vs. DML vs. DCL",
                "Creating Databases, Tables & defining attributes",
                "BTEQs & Table types – Set vs Multiset",
                "Views / Materialized views"
              ]
            }
          ],
          summary: "By the end of this course, the candidate should have a basic understanding of DWH Essentials & SQL"
        } ,
        {
          week: "Week 2",
          topics: [
            {
              title: "Mastering SQL & Project Kick-off",
              subTopics: [
                "Stored Procedures & Functions",
                "Indexing & Constraints",
                "Aggregate functions, Window aggregate functions, Order analytical functions",
                "SQL Ad-hoc reporting & analysis",
                "Business Discovery for DWH – Project Kickoff"
              ]
            }
          ],
          summary: "By the end of this course, the candidate should have a Mastering SQL & Project Kick-off"
        },
        {
          week: "Week 3",
          topics: [
            {
              title: "Data warehouse Framework & Design",
              subTopics: [
                "Data Warehousing Structure Fundamentals",
                "Conceptual vs Logical vs Physical Data Model",
                "Introduction to Teradata’s HW Architecture",
                "Indexing – Primary Index vs. Primary Key",
                "Partitions & Data Storage – Hashing Algorithms",
                "Data Retention & Compressions",
                "Data Modelling & Schema design",
                "Introduction to ETL / ELT (Extract, Load & Transform)",
                "Star vs Snowflake vs Galaxy Constellation",
                "Data loading into staging layer – Readiness for Functional layer"
              ]
            }
          ],
          summary: "By the end of this course, the candidate should have a basic understanding of Data warehouse Framework & Design"
        } 
        ,
        {
          week: "Week 4",
          topics: [
            {
              title: "ETL Pipeline & Data Warehouse Building Blocks",
              subTopics: [
                "Implement data quality checks",
                "Implementation of Normalization – 3NF",
                "Apply transformations & develop functional layer",
                "Hands on with physical Data Model (Foundation layer)",
                "Hands on with Physical Data Model (Aggregate layer)",
                "Building Reconciliation Mechanism across layers of DWH Performance Monitoring & Automation",
                "ETL Automations using SLJM",
                "Performance Tuning",
                "Statistics",
                "Data Quality & Automations",
                "Viewpoint Query Monitoring, Health Monitoring, Workload Management, Query Spotlight",
                "Learning importance of Explain Plan & query Performance Optimization"
              ]
            }
          ],
          summary: "By the end of this course, the candidate should have a basic understanding of ETL Pipeline & Data Warehouse Building Blocks"
        },
        {
          week: "Week 5",
          topics: [
            {
              title: "BI Data Modelling",
              subTopics: [
                "Business Intelligence & its importance",
                "OLAP – Dimensional Modelling Fundamentals",
                "Dimensional Modelling Design with industrial use case",
                "Build & deploy Semantic layer",
                "Design Steps – Kimball’s Dimensional Modeling (Hands on)",
                "Implementation – Kimball’s Dimensional Modeling (Hands on)",
                "Managing DWH history through Slowly Changing Dimensions (SCDs) with use cases",
                "ROLAP vs MOLAP vs HOLAP",
                "Design & implementation of Kimball’s Dimensional Model technique – Semantic layer Readiness"
              ]
            }
          ],
          summary: "By the end of this course, the candidate should have a basic understanding of BI Data Modelling"
        } ,
        {
          "week": "Week 6 and 7",
          "topics": [
            {
              "title": "Microsoft Excel",
              "subTopics": [
                "Loading, Cleaning & Preparing data",
                "Managing the Data Model",
                "Pivot Table for Data Analysis",
                "Lookup Functions, Aggregate Functions & Merging techniques",
                "Data Analysis using Charts & Visualizations"
              ]
            },
            {
              "title": "Microsoft Power BI",
              "subTopics": [
                "Connect & Get Data from multiple Data sources",
                "Data Connection/Storage modes – Import, Live, Direct Query & Dual",
                "Reshaping and Transforming Data in Query Editor",
                "Data Enrichment (New business Fields)",
                "Data Modelling",
                "Understanding Cardinalities",
                "Understanding Filter Context",
                "Building Interactive Visualizations on implemented Dimensional Model",
                "Animated Visualization Implementation",
                "Roll-up/Roll-Down Capabilities",
                "Introduction to DAX Language",
                "Custom visualization in Power BI",
                "Creating DAX Measures",
                "Evaluating DAX Measures",
                "Leverage Calculate Functions functionality",
                "Time Intelligence – MTD, QTD and YTD Date Calculations",
                "Introduction to Power BI Services",
                "Scheduling Automated Reports Refresh",
                "Sharing Reports & Dashboards",
                "Mobile Dashboard Design",
                "Performance monitoring & debugging a slow running report – DAX Studio",
                "Business Use Case implementation in Power BI (Assignment)"
              ]
            }
          ],
          "summary": "By the end of this course, the candidate should have a basic understanding of BI Reporting & Analysis"
        } ,
        {
          "week": "Week 8 and 9",
          "topics": [
            {
              "title": "Visualization & Business Analysis",
              "subTopics": [
                "Connecting with Different Data Sources in Tableau",
                "Data preparation with Tableau",
                "Live Vs Extract",
                "Data Source Filters",
                "Basic Report Creation",
                "Understanding of Rows and Columns",
                "Leveraging the Use of Marks Labels to enrich information in Reports",
                "Visualization best practices with real world examples",
                "Grouping fields in Tableau",
                "Interactive Filters",
                "Types of filters",
                "Advanced Filter Calculations",
                "Enhancing user interactivity thorough parameters",
                "Pages",
                "Maps in Tableau",
                "Importing custom geocoding in Tableau",
                "Visualize your data on map through spatial files",
                "Building a Dashboard",
                "Leveraging the use of Interactivity in Dashboards through Actions",
                "Designing and implementation of dashboard",
                "Designing of dashboard for mobile & Tablets",
                "Extensions",
                "Enriching information by creating Calculated Fields",
                "Calculation Syntax",
                "Date/Logic/String Calculations",
                "Advance Calculations (LODs)",
                "LODs & real-world Use cases",
                "Visual analytics",
                "80-20 rule – Pareto Chart",
                "Business Use Case implementation in Tableau (Assignment)",
                "Career counseling & Final Assessment"
              ]
            }
          ]
        }

      ] ,

      instructors : [
        {
          name: "Nouman Mir",
          linkedin: "https://www.linkedin.com/in/nauman-mir-1b593285/",
          imgSrc: "/images/instructor-1.png",
          title: "DATA ANALYTICS EXPERT",
          socialLinks: [
            {
              platform: "LinkedIn",
              url: "https://www.linkedin.com/in/nauman-mir-1b593285/"
            }
          ],
          description: "Nauman has 13 years of experience in Analytics across many industries. His major expertise are in DWH, BI, Big Data and Contextual Marketing. He has been following his passion to train individuals and corporates for the last 7 years, locally and internationally."
        },
        {
          name: "Saba Farooqi",
          linkedin: "https://www.linkedin.com/in/saba-farooqi-44b88121b/",
          imgSrc: "/images/instructor-4.png",
          title: "ASSOCIATE PRACTICE MANAGER, BUSINESS INTELLIGENCE AT TERADATA",
          socialLinks: [
            {
              platform: "LinkedIn",
              url: "https://www.linkedin.com/in/saba-farooqi-44b88121b/"
            }
          ],
          description: "Saba Farooqi has over 13 years of extensive experience in Business Intelligence & Data warehousing solutions with focus on access layer design and foundation layout. She has managed teams and created benchmarks in semantic data modeling in telecom sector of Pakistan apart from ensuring on time delivery of programs & projects as product owner. With an emphasis on effective communication with stakeholders, she has expounded complex business objectives and product requirements to develop consensus over solutions and ensured customer satisfaction."
        } ,
        {
          name: "Yasir Mehmood",
          linkedin: "https://www.linkedin.com/in/yasir-mahmood-b42324132/",
          imgSrc: "/images/instructor-7.jpg",
          title: "BUSINESS INTELLIGENCE CONSULTANT AT TERADATA",
          socialLinks: [
            {
              platform: "LinkedIn",
              url: "https://www.linkedin.com/in/yasir-mahmood-b42324132/"
            }
          ],
          description: "A Computer Science graduate by qualification and a developing data enthusiast by choice, holds over 7 years of industry experience in the field of technology, data analytics, data warehousing, EL/TL, data mining, reporting & visualization. My qualifications & my actual job in the field of Business Intelligence & Data Warehousing have provided me with a well-rounded background and enabled to develop an analytical/logical approach to tasks, software skills and ability to work under pressure."
        }
      ]

    }, 

    //CLOUD-COMUTING
    {
      slug : 'cloud-computing' ,
      name : 'Cloud Computing' ,
      duration : "3 months" ,
      hours : 40 ,
      fee : 30000 ,
      imgSrc : "/images/cloud-computing-training.png" ,
      formLink : 'https://forms.gle/VK4BedhWWexJG7SAA',
      sideImg : '' , 

      description: [
        "This 40-hour hands-on training course on AWS (Amazon Web Services) offers a comprehensive journey from foundational concepts to expert-level proficiency. Participants will gain practical experience in cloud computing, infrastructure management, and AWS services, preparing them for a successful career in cloud technology."
      ],
      learningObjectives: [
        "Gain a clear understanding of cloud computing, including its advantages and various deployment models.",
        "Acquire insights into AWS's global infrastructure, including regions, availability zones, and edge locations.",
        "Learn practical skills for creating and managing an AWS account, including configuring billing preferences, budgeting, and setting up alarms.",
        "Understand IAM user management, password policies, and the importance of multi-factor authentication (MFA) for security.",
        "Explore fundamental AWS services such as EC2, S3, RDS, and CloudFront, along with their pricing and usage considerations.",
        "Develop hands-on experience in setting up and managing AWS resources and services." ,
        "Comprehend AWS billing and pricing models, including free services and cost management tools.",
        "Learn about different pricing models for EC2 instances and cost control features offered by AWS.",
        "Dive into a wide range of advanced AWS technologies, from database services to provisioning, computing, and storage services.",
        "Develop knowledge and skills related to cloud security, compliance, and penetration testing, understanding the Shared Responsibility Model and AWS security tools."
      ],

      curriculum: [
        {
          "week": "Section 1",
          "topics": [
            {
              "title": "Introduction to Cloud Concepts",
              "subTopics": [
                "What is Cloud Computing?",
                "Advantages of Cloud Computing",
                "Types of Cloud Computing",
                "Cloud Deployment Models"
              ]
            },
            {
              "title": "AWS Global Cloud Infrastructure",
              "subTopics": [
                "Introduction and Global Map Overview",
                "AWS Regions",
                "Availability Zones",
                "Edge Locations",
                "GovCloud Intro and Availability"
              ]
            },
            {
              "title": "Getting Started - Hands On",
              "subTopics": [
                "AWS Account Creation",
                "Billing Preferences, Budgets and Alarms",
                "Intro to Pricing Models and Tiers",
                "Intro to Shared Responsibility Model",
                "Change IAM Users Sign-in Link",
                "Activate MFA on Root Account",
                "Create individual IAM user",
                "Set a password policy"
              ]
            },
            {
              "title": "Top Level AWS Services and Concepts",
              "subTopics": [
                "Introduction",
                "Explain pricing and care about usage",
                "How to check pricing",
                "Credit card and bank information care",
                "EC2 - Setup and EC2 Instance",
                "Sessions Manager - Observe and Manage the present Session",
                "AMI - Free vs. Paid images, Observe a machine image, use a free one for Hands-On",
                "S3 - What is Object Storage, S3 Buckets - Intro, Setup and Usage, S3 Configurations, replication etc.",
                "CloudFront - Concept, Create an instance on the S3 bucket",
                "RDS - Theory",
                "Disable all services on Cloud Account"
              ]
            },
            {
              "title": "Hands-On exercises" ,
              subTopics : []
            },
            {
              "title": "Q&A" ,
              subTopics : []
            }
          ],
          "summary": "By the end of Section 1, participants will have a basic understanding of cloud computing concepts, AWS infrastructure, and hands-on experience with AWS services."
        } ,
        {
          "week": "Section 2",
          "topics": [
            {
              "title": "Billing and Pricing",
              "subTopics": [
                "Introduction",
                "Free Services",
                "Common Paid Services with Free Tiers",
                "AWS Support Plans",
                "AWS Marketplace",
                "AWS Trusted Advisor",
                "Consolidated Billing",
                "AWS Cost Explorer",
                "AWS Budgets",
                "TCO Calculator",
                "AWS Landing Zone",
                "Resource Groups and Tagging",
                "AWS Cost and Usage Report",
                "AWS Organizations and Accounts"
              ]
            },
            {
              "title": "EC2 Pricing Models",
              "subTopics": [
                "On-Demand",
                "Reserved",
                "Spot",
                "Dedicated"
              ]
            },
            {
              "title": "Services Recap and New Services",
              "subTopics": [
                "S3",
                "EC2",
                "AMI",
                "Auto Scaling Groups",
                "Elastic Load Balancer",
                "RDS",
                "Lambda",
                "CloudFront",
                "AWS QuickStart"
              ]
            }
          ],
          "summary": "Section 2 of the course focuses on AWS billing and pricing, including various pricing models and cost management tools. It also includes a recap of key AWS services and introduces new services for advanced cloud computing knowledge."
        } ,
        {
          "week": "Section 3",
          "topics": [
            {
              "title": "Technologies in AWS",
              "subTopics": [
                "AWS Organizations and Accounts",
                "AWS Networking",
                "Database Services",
                "RDS",
                "Aurora",
                "Aurora Serverless",
                "Redshift",
                "ElastiCache",
                "DynamoDb",
                "DocumentDb",
                "Neptune"
              ]
            },
            {
              "title": "Provisioning Services",
              "subTopics": [
                "Computing Services",
                "ECS",
                "EKS",
                "EBS",
                "Lambda",
                "Fargate",
                "Batch"
              ]
            },
            {
              "title": "Storage Services",
              "subTopics": [
                "S3",
                "Glacier",
                "EBS",
                "EFS",
                "Snowball",
                "Business Centric Services",
                "Enterprise Integration",
                "Logging Services"
              ]
            },
            {
              "title": "Cloud Security with AWS (with hands-on)",
              "subTopics": [
                "Shared Responsibility Model",
                "AWS Compliance programs",
                "AWS Artifact",
                "Amazon Inspector",
                "AWS WAF",
                "AWS Shield",
                "Penetration Testing",
                "Guard Duty",
                "Key Management Service",
                "Amazon Macie",
                "Security Groups vs NACLs",
                "AWS VPN"
              ]
            },
            // {
            //   "title": "AWS/Cloud Abbreviations including all service names" ,
            //   subTopics : []
            // }
          ],
          "summary": "Section 3 covers advanced AWS technologies, cloud security, and essential cloud-related abbreviations. Participants will gain in-depth knowledge of AWS services, provisioning, computing, storage, and cloud security with hands-on practice."
        } ,
        {
          "week": "Section 4",
          "topics": [
            {
              "title": "Serverless Architecture" ,
              subTopics : []
            },
            {
              "title": "Revision – Complete Content",
              subTopics : []
            },
            {
              "title": "Quiz 1",
              subTopics : []
            },
            {
              "title": "Quiz 2",
              subTopics : []
            },
            {
              "title": "Project Submission and Presentation",
              subTopics : []
            }
          ],
          "summary": "Section 4 covers serverless architecture, a comprehensive revision of the complete course content, two quizzes to assess knowledge, and the submission and presentation of the course project."
        }
        
      ],

      instructors : [
        {
            name: "Muhammad Umer Ali",
            imgSrc: "/images/instructor-6.jpg",
            title: "SENIOR BIG DATA ENGINEER AT IBM",
            linkedin: "https://www.linkedin.com/in/mumerali/",
            description: "He has 10+ years of experience in the field of Data Warehouse, Big Data and Cloud. He is an expert in Enterprise Data warehouses, Implementation of Big Data Applications and Hybrid ETL Architecture. Some of his other specialties include Data Modelling, Python, C/C++ & Java, Unix/Linux environments, shell scripting and Cluster based hardware/software design - MPP and SMP systems (MPI, OpenMPI, CUDA)."
        }
      ]

    }


]

export default trainings 