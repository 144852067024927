import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga';

export default function GoogleAnalytics() {

    const location = useLocation();
    ReactGA.initialize('G-V6Y262DJB5');
    
    useEffect(() => {
      ReactGA.pageview(location.pathname + location.search);
    }, [location])

    return(
        <Outlet/>
    )
}
