import React from 'react'
import './../Course/Course.css'
import { Link, useLocation , useNavigate } from 'react-router-dom'
import corporateTrainings from '../../variables/corporateTrainings'
import {Helmet} from "react-helmet";

export default function CorporateCourse() {

    const navigate = useNavigate()
    let { pathname } = useLocation()
    let slug = pathname.split('/')[2]
    console.log("path : " , slug )

    let course = corporateTrainings.find( el => el.slug === slug )

    
  return (
    <div className="site-wrapper course-page">
        <Helmet>
            <title>{course?.name}</title>
        </Helmet>
        <section className="course-detail section-spacing">
            <div className="container">
            <div className="course-detail-header d-block d-lg-none mt-5">
                <h1 className="text-purple fw-bold">
                {course.name}
                </h1>
            </div>
            <div className="course-detail-content row mt-4">
                <div className="course-detail-left col-lg-8">
                    <div className="course-detail-header d-none d-lg-block">
                        <h1 className="text-purple fw-bold">
                        {course.name}
                        </h1>
                    </div>
                    <main className="course-detail-tab-parent mt-lg-4">
                        <section className="tab-content" id="pills-tabContent">
                            <div className=" course-overview tab-pane fade show active mb-5"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                <h4 className="text-purple fw-bold mb-4">Course Description</h4>
                                <p style={{'max-width' : '75ch'}} className='text-justify'>
                                This specialized training program is exclusively designed for corporate clients, offering customizable course content that can be finely tuned to align with your company's specific requirements and preferences.
                                </p>
                            </div>
                            <div className="d-inline-flex align-items-center p-3 disclaimer" style={{'backgroundColor' : '#F5F5F5' }}>
                              <h5 className='mb-0 text-blue fw-bold'>Disclaimer : </h5> 
                              <p className='mb-0 ps-2 text-blue '>For groups of 15 or more</p>
                            </div>
                            <br />
                            <br />
                            <br />
                            <Link
                            to={'/contact-us'}
                            className="generic-btn-1  d-inline"
                            >
                            Get A Quote
                            </Link>
                        </section>
                    </main>
                </div>
            </div>
            </div>
        </section>

    </div>
  )
}
